import { IconButton } from '@instructure/ui-buttons';
import { DrawerLayout } from '@instructure/ui-drawer-layout';
import { IconHamburgerLine, IconXLine } from '@instructure/ui-icons';
import { Mask } from '@instructure/ui-overlays';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import { AsyncState } from '../../uiCommon/redux/async';
import { RootState } from '../redux';
import { getOktaUser } from '../redux/okta';
import { getUser as getSistemicUser, User } from '../redux/users';

import ConnectedAlert from './Alert';
import LoadingScreen from './LoadingScreen';
import Logo from './Logo';
import Main from './Main';
import { modalMapFactory } from './modals';
import ConnectedModalContainer, { ModalMap } from './modals/ModalContainer';
import ConnectedNav from './Nav';
import { IOktaContext } from './types';

type MappedProps = {
  getSistemicUserState: AsyncState<User>;
};

type Props = MappedProps &
  IOktaContext & {
    getOktaUser: (oktaAuth: OktaAuth) => void;
    getSistemicUser: (oktaAuth: OktaAuth) => void;
  };

type State = {
  isTrayOpen: boolean;
  isTrayOverlayed: boolean;
};

export class App extends Component<Props, State> {
  state = {
    isTrayOpen: true,
    isTrayOverlayed: false,
  };

  // Fetch user info when app is mounted
  componentDidMount(): void {
    const { oktaAuth } = this.props;
    const okta = config.okta;

    if (!okta.disabled) {
      this.props.getSistemicUser(oktaAuth);
      this.props.getOktaUser(oktaAuth);
    }
  }

  handleOverlayTrayChange = (isTrayOverlayed: boolean): void => {
    this.setState({
      isTrayOverlayed,
    });
  };

  handleTrayDismiss = (): void => {
    this.setState({
      isTrayOpen: false,
    });
  };

  handleTrayToggle = (): void => {
    this.setState(({ isTrayOpen }) => {
      return {
        isTrayOpen: !isTrayOpen,
      };
    });
  };

  renderTray = (): ReactNode => (
    <View as="div" padding="small" width="14rem">
      <IconButton
        screenReaderLabel={I18n.t('Close Tray')}
        withBackground={false}
        withBorder={false}
        onClick={this.handleTrayToggle}
      >
        <IconXLine />
      </IconButton>
      <View as="div" margin="0 auto x-large" padding="x-small">
        <Logo />
        <View as="div" margin="x-large 0 0" padding="0 xx-small">
          <ConnectedNav />
        </View>
      </View>
    </View>
  );

  renderContent = (): ReactNode => {
    const { isTrayOpen } = this.state;

    // hide icon when tray is open
    const props = isTrayOpen
      ? {
          style: {
            color: 'transparent',
          },
        }
      : {};

    return (
      <View as="div" padding="small">
        <IconButton
          id="open-tray-button"
          screenReaderLabel={I18n.t('Open Tray')}
          withBackground={false}
          withBorder={false}
          onClick={this.handleTrayToggle}
        >
          <IconHamburgerLine {...props} />
        </IconButton>
        <ConnectedAlert margin="medium" />
        <ConnectedModalContainer
          modalMap={modalMapFactory(this.props.oktaAuth) as unknown as ModalMap}
        />
        <View as="div" margin="0 auto x-large" padding="x-small" maxWidth="80rem">
          <Main />
        </View>
      </View>
    );
  };

  render(): ReactNode {
    const { isTrayOpen, isTrayOverlayed } = this.state;
    const { getSistemicUserState } = this.props;

    return !config.okta.disabled && (!getSistemicUserState.data || getSistemicUserState.pending) ? (
      <LoadingScreen message={I18n.t('Loading Sistemic')} />
    ) : (
      <View as="div" height="100%">
        {isTrayOpen && isTrayOverlayed && (
          <Mask
            onClick={
              this.handleTrayDismiss // can dismiss tray when it is overlayed in narrow screen
            }
          />
        )}
        <DrawerLayout onOverlayTrayChange={this.handleOverlayTrayChange} minWidth="28rem">
          <DrawerLayout.Tray
            label={I18n.t('navigation')}
            placement="start"
            role="navigation"
            area-label="navigation"
            title="navigation"
            open={isTrayOpen}
            onDismiss={this.handleTrayDismiss}
          >
            {this.renderTray()}
          </DrawerLayout.Tray>
          <DrawerLayout.Content label={I18n.t('Content')} role="main">
            {this.renderContent()}
          </DrawerLayout.Content>
        </DrawerLayout>
      </View>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => ({
  getSistemicUserState: state.users.getUser,
});

export default withOktaAuth(
  connect(mapStateToProps, {
    getOktaUser,
    getSistemicUser,
  })(App),
);
