import { IconButton } from '@instructure/ui';
import { Alert } from '@instructure/ui-alerts';
import { Grid } from '@instructure/ui-grid';
import { Heading } from '@instructure/ui-heading';
import { IconCopyLine } from '@instructure/ui-icons';
import { View } from '@instructure/ui-view';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import I18n from 'i18n-js';
import { noop } from 'lodash';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import config from 'webapp-configuration';

import ConnectedAsyncModal from '../../../uiCommon/components/modals/AsyncModal';
import Select, { SelectOption } from '../../../uiCommon/components/Select';
import TextInput from '../../../uiCommon/components/TextInput';
import { AsyncState } from '../../../uiCommon/redux/async';
import { RootState } from '../../redux';
import { Agent } from '../../redux/agent';
import { AlertState, setAlert } from '../../redux/alert';
import { listJobs, ListJobsParams, ScheduleData, Job } from '../../redux/job';
import { User } from '../../redux/okta';
import {
  createSchedule,
  updateSchedule,
  listSchedules,
  ScheduleOption,
  Schedule,
} from '../../redux/schedule';
import DateTimePicker from '../DateTimePicker';
import Frequency from '../Frequency';
import JobDerivedLink from '../JobDerivedLink';
import { JOB_TYPES } from '../JobName';
import NotificationSettings from '../NotificationSettings';
import scheduleConfigs from '../scheduleConfigs';
import { FILES } from '../scheduleConfigs/RosterCompareConfig';
import { ScheduleDataChange } from '../scheduleConfigs/types';
import {
  MILLIS_PER_SECOND,
  DAYS_PER_YEAR,
  NOOP_TYPE,
  now,
  FETCH_CONFIGURATION_ENABLED_PREFIXES,
  copyToClipboard,
} from '../util';

const getJobNames = () => {
  const jobs = [
    'rawData',
    'rostering',
    'rosterCompare',
    'remapSisIds',
    'assignment',
    'gpbAccount',
    'accountReport',
    'grade',
    'assignmentGPBAccount',
    'onerosterCsvGpb',
    'kimonoAssignmentGPB',
    'resetIntegrationData',
  ];

  if (config.enabledRosterFetchVerification) {
    jobs.push('rosterFetchVerification');
  }

  return jobs;
};

const JOB_DESCRIPTIONS: { [key: string]: string } = {
  rawData: I18n.t('Fetch raw data from SIS'),
  rostering: I18n.t('Import roster data (users, courses, enrollments, etc.) from a SIS to Canvas'),
  rosterCompare: I18n.t('Compare two uploaded JSON files'),
  assignment: I18n.t('Send grades to SIS for a supplied list of Canvas course IDs'),
  gpbAccount: I18n.t('Send grades to SIS for all courses with nightly_sync turned on'),
  accountReport: I18n.t('Send Canvas account report CSV file to SFTP'),
  remapSisIds: I18n.t('Update the SIS IDs in Canvas'),
  grade: I18n.t('Send grades to SIS from account report "Grade Export"'),
  assignmentGPBAccount: I18n.t('Send grades to SIS from account report "SIS Submission Reports"'),
  onerosterCsvGpb: I18n.t(
    'Send OneRoster CSV grades to SIS from account report "SIS Submission Reports"',
  ),
  kimonoAssignmentGPB: I18n.t('Send grades to SIS via Kimono'),
  rosterFetchVerification: I18n.t('Verify rostering snapshot'),
};

type MappedProps = {
  createScheduleState: AsyncState<Schedule>;
  updateScheduleState: AsyncState<Schedule>;
  user?: User;
  agent?: Agent;
  isUploading: boolean;
};

type HOCProps = MappedProps & {
  oktaAuth: OktaAuth;
  listJobs: (oktaAuth: OktaAuth, params: ListJobsParams) => void;
  listSchedules: (oktaAuth: OktaAuth, id: string) => void;
  createSchedule: (oktaAuth: OktaAuth, option: ScheduleOption) => void;
  updateSchedule: (oktaAuth: OktaAuth, scheduleId: string, option: ScheduleOption) => void;
  setAlert: (alert: AlertState) => void;
};

export type OwnProps = {
  jobsPerPage?: number;
  schedule?: Schedule;
  job?: Job;
};

export type Props = HOCProps & OwnProps;

type State = {
  jobType: string;
  dateTime: DateTime;
  interval: number;
  scheduleData: ScheduleData;
  isScheduleDataValid?: boolean;
};

const getScheduleDataFromAgent = (jobType: string, agent: Agent | undefined): ScheduleData => {
  if (jobType === 'gpbAccount') {
    return {
      gradedSince: get(agent, 'config.args[0].sis.args[0].options.gradedSince'),
    };
  }

  if (jobType === 'accountReport') {
    return {
      accountReport: get(agent, 'config.args[0].accountReport', ''),
    };
  }

  if (!['remapSisIds', 'rostering', 'accountReport'].includes(jobType)) {
    return {};
  }

  const instType = get(agent, 'config.args[0].inst.type', '');
  const sisType = get(agent, 'config.args[0].sis.type', '');
  const { schools, filters, excludedEndpoints } = get(agent, 'config.args[0].sis.args[0]', {});

  const data: ScheduleData = FETCH_CONFIGURATION_ENABLED_PREFIXES.find((prefix) =>
    sisType.startsWith(prefix),
  )
    ? {
        rosterFetch: {
          schools,
          filters,
          excludedEndpoints,
        },
      }
    : {};

  if (instType === NOOP_TYPE) {
    data.stopAt = jobType === 'rostering' ? 'rosterSubmit' : 'remapSisIdsSubmit';
  } else {
    if (jobType === 'remapSisIds') {
      data.rosterImports = [{}];
    } else {
      data.rosterImports = get(agent, 'config.args[0].roster.imports', []);
    }
  }

  if (jobType === 'remapSisIds') {
    data.accountReport = get(agent, 'config.args[0].accountReport', '');
  }

  data.slackUserId = get(agent, 'slackId');

  return data;
};

const getStateFromAgent = (agent: Agent | undefined): State => {
  const timezone = get(agent, 'timezone');
  const jobType = 'rostering';
  let dateTime = now();

  if (timezone) {
    dateTime = dateTime.setZone(timezone, {
      keepLocalTime: false,
    });
  }
  return {
    jobType,
    dateTime,
    interval: 0,
    scheduleData: getScheduleDataFromAgent(jobType, agent),
    isScheduleDataValid: true,
  };
};

const getStateFromSchedule = (schedule: Schedule): State => {
  let dateTime = DateTime.fromMillis(schedule.next * MILLIS_PER_SECOND);

  if (schedule.data.timezone) {
    dateTime = dateTime.setZone(schedule.data.timezone, {
      keepLocalTime: false,
    });
  }
  return {
    jobType: schedule.type,
    dateTime,
    interval: schedule.interval,
    scheduleData: schedule.data,
    isScheduleDataValid: true,
  };
};

const getStateFromJob = (job: Job): State => {
  let dateTime = now();

  if (job.data.timezone) {
    dateTime = dateTime.setZone(job.data.timezone, {
      keepLocalTime: false,
    });
  }
  return {
    jobType: job.type,
    dateTime,
    interval: 0,
    scheduleData: {
      ...job.data,
      rosterImports:
        job.data.rosterImports && job.data.rosterImports.map(({ state, ...rest }) => rest),
    },
    isScheduleDataValid: true,
  };
};

const getState = ({ job, schedule, agent }: Props) => {
  if (job) {
    return getStateFromJob(job);
  }
  if (schedule) {
    return getStateFromSchedule(schedule);
  }
  return getStateFromAgent(agent);
};

export class JobScheduleModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = getState(props);
  }

  componentDidUpdate(prevProps: Props): void {
    const { createScheduleState, oktaAuth, agent, jobsPerPage, job } = this.props;
    const { data } = createScheduleState;
    const agentId = get(agent, 'id') || get(job, 'data.agentId');

    if (data && data !== prevProps.createScheduleState.data && agentId) {
      this.props.listJobs(oktaAuth, {
        tag: agentId,
        count: jobsPerPage,
      });
      this.props.listSchedules(oktaAuth, agentId);
    }
  }

  handleSave = (): void => {
    const { job, user, oktaAuth, agent, schedule } = this.props;
    const { jobType, dateTime, interval, scheduleData } = this.state;
    const agentId = get(agent, 'id') || get(job, 'data.agentId');

    if (schedule) {
      this.props.updateSchedule(oktaAuth, schedule.id, {
        agentId: schedule.agentId,
        type: jobType,
        next: dateTime.toMillis() / MILLIS_PER_SECOND,
        interval: interval,
        data: {
          ...schedule.data,
          ...scheduleData,
          timezone: dateTime.zoneName,
          user: user && {
            name: user.name,
            email: user.email,
            role: 'admin',
          },
        },
      });
    } else if (agentId) {
      this.props.createSchedule(oktaAuth, {
        agentId,
        type: jobType,
        next: dateTime.toMillis() / MILLIS_PER_SECOND,
        interval: interval || null,
        queue: get(job, 'queue'),
        data: {
          timezone: dateTime.zoneName,
          user: user && {
            name: user.name,
            email: user.email,
            role: 'admin',
          },
          ...scheduleData,
        },
      });
    }
  };

  handleScheduleDataChange = (change: ScheduleDataChange): void => {
    this.setState(change);
  };

  handleNameChange = (name: string): void => {
    const { scheduleData } = this.state;

    this.setState({
      scheduleData: {
        ...scheduleData,
        name,
      },
    });
  };

  handleNotesChange = (notes: string): void => {
    const { scheduleData } = this.state;

    this.setState({
      scheduleData: {
        ...scheduleData,
        notes,
      },
    });
  };

  handleTypeChange = ({ id: jobType }: SelectOption): void => {
    const { agent } = this.props;

    this.setState({
      jobType,
      scheduleData: getScheduleDataFromAgent(jobType, agent),
      isScheduleDataValid: true,
    });
  };

  handleIntervalChange = (seconds: number): void => {
    this.setState({
      interval: seconds,
    });
  };

  handleSlackUserIdChange = (slackUserId?: string): void => {
    const { scheduleData } = this.state;

    this.setState({
      scheduleData: {
        ...scheduleData,
        slackUserId,
      },
    });
  };

  handleDateTimeChange = (dateTime: DateTime): void => {
    this.setState({
      dateTime,
    });
  };

  renderHeader(): ReactNode {
    const { schedule } = this.props;
    const { scheduleData } = this.state;

    return (
      <Fragment>
        <Heading level="h2">
          {schedule ? I18n.t('Update Schedule') : I18n.t('Create Schedule')}
        </Heading>
        <JobDerivedLink scheduleData={scheduleData} />
      </Fragment>
    );
  }

  renderExpiryAlert(iso: string): ReactNode {
    const expiry = DateTime.fromISO(iso).plus({
      days: DAYS_PER_YEAR,
    });
    const hasExpired = expiry.toMillis() < now().toMillis();
    const days = expiry.toRelative({ unit: 'days' });

    return (
      <Alert
        variant={hasExpired ? 'error' : 'warning'}
        margin="0 0 medium"
        theme={{
          boxShadow: 'none',
        }}
      >
        {hasExpired
          ? I18n.t('Files saved by parent job expired %{days}', { days })
          : I18n.t('Files saved by parent job will expire %{days}', { days })}
      </Alert>
    );
  }

  render(): ReactNode {
    const { agent, schedule, job, createScheduleState, updateScheduleState, isUploading } =
      this.props;
    const { jobType, interval, dateTime, scheduleData, isScheduleDataValid } = this.state;
    const pending = createScheduleState.pending || updateScheduleState.pending;
    const error = createScheduleState.error || updateScheduleState.error;
    const ScheduleConfig = scheduleConfigs[jobType];

    const disabled =
      !isScheduleDataValid ||
      (jobType === 'rosterCompare' && isUploading) ||
      (jobType === 'assignment' && !scheduleData.courseIds);

    const options = getJobNames().map((type) => {
      return {
        id: type,
        label: JOB_TYPES[type],
      };
    });

    let renderCopyIdButton;

    if (schedule) {
      renderCopyIdButton = (
        <IconButton
          screenReaderLabel={I18n.t('Copy Schedule ID')}
          onClick={() => {
            copyToClipboard(
              schedule.id,
              () =>
                this.props.setAlert({
                  variant: 'success',
                  message: I18n.t('Schedule ID successfully copied to clipboard.'),
                }),
              (err) =>
                this.props.setAlert({
                  variant: 'error',
                  message: `${I18n.t('Unable to copy Schedule ID to clipboard')}: ${err.message}`,
                }),
            );
          }}
          withBackground={false}
          withBorder={false}
          size="small"
        >
          <IconCopyLine />
        </IconButton>
      );
    }

    return (
      <ConnectedAsyncModal
        label={I18n.t('Job Schedule Modal')}
        modalClass="JobScheduleModal"
        header={this.renderHeader()}
        saveButtonText={schedule ? I18n.t('Update') : I18n.t('Create')}
        saveButtonDataAttribute={schedule ? 'update-schedule' : 'create-schedule'}
        onSave={this.handleSave}
        pending={pending}
        error={error}
        disabled={disabled}
        closeOnSave
        overflow="fit"
      >
        {scheduleData.derivedISO && this.renderExpiryAlert(scheduleData.derivedISO)}
        <Grid>
          {!schedule && !job && (
            <Grid.Row>
              <Grid.Col width="auto">
                <Select
                  renderLabel={I18n.t('Type')}
                  options={options}
                  onChange={this.handleTypeChange}
                  data-select="select-job-type"
                  selectedOptionId={jobType}
                  messages={[
                    {
                      type: 'hint',
                      text: JOB_DESCRIPTIONS[jobType],
                    },
                  ]}
                  width="34rem"
                />
              </Grid.Col>
            </Grid.Row>
          )}
          {schedule && (
            <Grid.Row>
              <Grid.Col>
                <TextInput
                  layout={'stacked'}
                  renderLabel={I18n.t('ID')}
                  defaultValue={schedule.id}
                  readOnly
                  onChange={noop}
                  interaction="readonly"
                  renderAfterInput={renderCopyIdButton}
                />
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col width="auto">
              <TextInput
                layout={'stacked'}
                renderLabel={I18n.t('Name')}
                defaultValue={scheduleData.name || ''}
                messages={[
                  {
                    type: 'hint',
                    text: I18n.t('This name will show up on LTI screens as well'),
                  },
                ]}
                onChange={(text) => this.handleNameChange(text)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <TextInput
                layout={'stacked'}
                label={I18n.t('Notes')}
                defaultValue={scheduleData.notes || ''}
                as={'TextArea'}
                onChange={(text) => this.handleNotesChange(text)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row margin={schedule ? '0' : 'medium 0 0'}>
            <Grid.Col width="auto">
              <Frequency
                defaultInterval={interval}
                disabledOptions={schedule ? new Set(['once']) : new Set()}
                onChange={this.handleIntervalChange}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row margin="medium 0 0">
            <Grid.Col width="auto">
              <DateTimePicker date={dateTime} onChange={this.handleDateTimeChange} />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col width="auto">
              <NotificationSettings
                defaultSlackId={scheduleData.slackUserId}
                onChange={this.handleSlackUserIdChange}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
        {agent && ScheduleConfig && (
          <View as="div" margin="medium 0 0">
            <ScheduleConfig
              agent={agent}
              jobType={jobType}
              scheduleData={scheduleData}
              isScheduleDataValid={!!isScheduleDataValid}
              onChange={this.handleScheduleDataChange}
            />
          </View>
        )}
      </ConnectedAsyncModal>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedProps => {
  const { schedule, okta, agent, uploads } = state;

  return {
    createScheduleState: schedule.createSchedule,
    updateScheduleState: schedule.updateSchedule,
    user: okta.user.data,
    agent: agent.readAgent.data,
    isUploading:
      get(uploads, [FILES.ORIGINAL, 'pending'], false) ||
      get(uploads, [FILES.EXPECTED, 'pending'], false),
  };
};

export default withOktaAuth(
  connect(mapStateToProps, {
    listJobs,
    listSchedules,
    createSchedule,
    updateSchedule,
    setAlert,
  })(JobScheduleModal),
);
